import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import { PageLayout, CardNavigation, HeroElement, About,  } from '@fishrmn/fishrmn-components';




import Layout from '../components/Layout';
import { getPageHeroImages, getPageGalleryImages } from '../utils/images';

export default class extends React.PureComponent {

  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Order Online</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={ 
              <HeroElement
                header={ "Order Online" }
                tagline={ "" }
                images={ getPageHeroImages(pageComponents) }
                showMap={ false }
                ctas={ [  ] }
                ctaInverted={ true }
                ctaColor={ "white" }
                containerColor={ "primary" }
                textColor={ "white" }
                showMultiple={ true }
                overlay={ true }
                fullWidth={ true }
                parallax={ false }
                containerAs={ "none" }
                height={ 500 }
                gutter={ false }
              />
           }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={ "middle" }
            textAlign={ "center" }
          >
            <Grid.Column width={ 12 }>
              <About
                content={ 'Please check back soon to order online.' }
                header={ 'Online Order - Coming Soon!' }
                centerContent={ true }
                headerAs={ "h1" }
                withContainer={ true }
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName 
          aboutMarkdown 
        }
      }
    }
    fishermanWebsitePage(pageType: {eq: "Order Online"}) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;